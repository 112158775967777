// @flow
import { setDefaultLevel as setLoggerLevel, LEVELS as LOG_LEVELS } from "common/logger";
import uploaderConsts from "uploader/common/consts";
import createWidget from "./global/all/createWidget";
import applyPageStyles from "./global/all/pageStyles";

type GlobalsType = {
	cloudName: ?string,
	apiKey: ?string,
};

((win: Object) => {
	//TODO: revert back to VERSION instead of WIDGET_VERSION when using only the new pipeline
	// eslint-disable-next-line no-undef
	const widgetVersion = WIDGET_VERSION;
	const globals: GlobalsType = {
		cloudName: null,
		apiKey: null,
	};
	const $ = (win.jQuery ? win.jQuery : (win.$ && win.$.fn && win.$.fn.jquery ? win.$ : null));
	const debug = win.location.search.indexOf("debug=true") > -1;
	const dev = win.location.search.indexOf("dev=true") > -1;

	setLoggerLevel(debug ? LOG_LEVELS.LOG : LOG_LEVELS.WARN);

	applyPageStyles();

	const getWidgetOptions = (options) => ({
		...globals,
		dev,
		debug,
		...options,
		widgetVersion,
		$,
	});

	//* ********************************************* EXTERNAL API **********************************************
	// eslint-disable-next-line no-multi-assign
	const cld = win.cloudinary = win.cloudinary || {};

	cld.applyUploadWidget = (element, options, widgetCallback?: Function) => createWidget(getWidgetOptions(options), widgetCallback, element);

	cld.createUploadWidget = (options, widgetCallback?: Function) => cld.applyUploadWidget(null, options, widgetCallback);

	cld.openUploadWidget = (options, widgetCallback?: Function) => cld.createUploadWidget(options, widgetCallback).open();

	cld.setCloudName = (name: string) => {
		globals.cloudName = name;
	};

	cld.setAPIKey = (key: string) => {
		globals.apiKey = key;
	};

	cld.WIDGET_SOURCES = { ...uploaderConsts.SOURCES };

	cld.WIDGET_VERSION = widgetVersion;

	if ($) {
		$.fn.cloudinary_upload_widget = function (options, widgetCallback?: Function) {
			cld.applyUploadWidget($(this)[0], options, widgetCallback);
		};
	}
})(self);
