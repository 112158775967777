export default {
	QUEUE_STATUS: {
		NONE: 0,
		RUNNING: 1,
		FINISHED: 2,
	},

	VIEW_TYPES: {
		MODAL: 1,
		INLINE: 2,
	},

	SOURCES: {
		LOCAL: "local",
		URL: "url",
		CAMERA: "camera",
		IMAGE_SEARCH: "image_search",
		GOOGLE_DRIVE: "google_drive",
		DROPBOX: "dropbox",
		FACEBOOK: "facebook",
		INSTAGRAM: "instagram",
		SHUTTERSTOCK: "shutterstock",
	},

	ABORTED: "__aborted__",

	RESOURCE_TYPES: {
		AUTO: "auto",
		IMAGE: "image",
		VIDEO: "video",
		RAW: "raw",
	},

	BATCH_CANCEL_REASONS: {
		MAX_EXCEEDED: "MAX_EXCEEDED", // reached max allowed uploads in this session
		INVALID_PUBLIC_ID: "INVALID_PUBLIC_ID", // public id from advanced options isnt valid
		INTEGRATION: "INTEGRATION", // integration method 'pre_batch' returned cancel
	},

	SNAKE_API_SERVER_PARAMS: {
		resourceType: "resource_type",
		useFilename: "use_filename",
		uniqueFilename: "unique_filename",
		publicId: "public_id",
		faceCoordinates: "face_coordinates",
		customCoordinates: "custom_coordinates",
		apiKey: "api_key",
		uploadPreset: "upload_preset",
		auditContext: "audit_context",
		qualityAnalysis: "quality_analysis",
	},

	NO_METADATA_REQUIRED: "NO_METADATA_REQUIRED",
};
