
export const WIDGET_VIEW_TYPES = {
	INITIAL: "initial",
	EXPANDED: "expanded",
	MINI: "minimized",
};

export const DIRECTION = {
	HORIZONTAL: "horizontal",
	VERTICAL: "vertical",
};

export default {
	DIRECTION,
	WIDGET_VIEW_TYPES,

	NOTIFICATION_TYPES: {
		WARNING: 1,
		ERROR: 2,
	},

	QUEUE: "queue",
	CROP: "crop",
	ABOUT: "about",
	METADATA: "metadata",

	OAUTH_STORAGE_TOKEN: "CLD_UW_SERVICE_OAUTH_TOKEN",

	MAX_INLINE_MENU_SOURCES: 8,
};

export const CLOSE_PROMPT_WIDTH = 70;
