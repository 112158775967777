// @flow

const isString = (subject: any) => typeof subject === "string";

const createElement = (type: string, attrs?: ?Object, className?: ?string, data?: ?Object, win: ?Object = null) : any => {
	win = win || self;
	const element = win.document.createElement(type);

	attrs = attrs || {};

	if (className) {
		attrs.class = className;
	}

	if (attrs) {
		const flowAttrs = attrs; // flow is stupid! :(
		Object.keys(flowAttrs).forEach((key) => element.setAttribute(key, flowAttrs[key]));
	}

	if (data) {
		const flowData = data; // flow is stupid! :(
		Object.keys(flowData).forEach((key) => element.dataset[key] = flowData[key]);
	}

	return element;
};

const getElement = (selectorOrElement: any, win: ?Object = null) => {
	win = win || self;

	return isString(selectorOrElement)
		? win.document.querySelector(selectorOrElement)
		: selectorOrElement;
};

const removeElement = (element: HTMLElement) => {
	if (element.parentNode) {
		element.parentNode.removeChild(element);
	}
};

const appendElement = (element: HTMLElement, parent?: HTMLElement) => {
	const actualParent = parent || document.body;
	(actualParent: any).appendChild(element);
};

const addStyles = (element: HTMLElement, styles: Object) => {
	Object.keys(styles).forEach((key: any) => {
		element.style[key] = styles[key];
	});
};

const showElement = (element: HTMLElement) => {
	addStyles(element, { display: "block" });
};

const hideElement = (element: HTMLElement) => {
	addStyles(element, { display: "none" });
};

export {
	createElement,
	getElement,
	removeElement,
	appendElement,
	addStyles,
	showElement,
	hideElement,
};
