export default {
	MINIFIED_WIDTH: 500,
	MINIFIED_HEIGHT: 55,
	MINIFIED_POSITION: "right:35px",
	MINIFIED_MEDIA_QUERY: "(min-width: 767px)",
	INLINE_HEIGHT: 610,
};

export const DISPLAY_VALUES = {
	HIDDEN: "hidden",
	SHOWN: "shown",
};

export const DEV_PORT =  "9990";
