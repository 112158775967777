// @flow
const camelCapitalize = (name: string, index: number) => (index === 0 ? name
	: name.substr(0, 1).toUpperCase() + name.substr(1));

export const camelizeFromSnake = (name: string) => name.split("_").map(camelCapitalize).join("");

const hasSnake = (name: string) => (name.indexOf("_") > 0); // ignores names that start with _

export default (obj: Object) : Object => Object.keys(obj).reduce((res, key) => {
	const newKey = hasSnake(key) ? camelizeFromSnake(key) : key;
	res[newKey] = obj[key];
	return res;
}, {});
