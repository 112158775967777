// @flow
import getLogger from "common/logger";
import { MESSAGE_TYPES } from "uploader/common/events";
import { PAGE_INTEGRATION_PARAMS } from "uploader/common/widgetParams";
import initComms from "./comms";
import initIframe from "./frameController";
import {
	getPageElement,
	initUploadButton,
	processUploadResult,
	revertUploadButton,
	clearThumbnails,
} from "./pageIntegrations";
import { DISPLAY_VALUES, DEV_PORT } from "./consts";
import { getElement } from "./utils";
import initializeWidgetParams, { processParams } from "./widgetParamsInitializer";
import initUploadsHandler from "./clientUploadsHandler";
import type { FrameApi } from "./frameController";

//TODO: move to config json after split to widget/app
const OLD_SUB_DOMAIN = "widget",
	APP_SUB_DOMAIN = "upload-widget",
	APP_TOP_DOMAIN = "cloudinary.com";

const logger = getLogger();
let widgetCounter = 0;

export const getWidgetOptions = (options: Object, element: ?HTMLElement) => {
	const wOpts = initializeWidgetParams(options, element);

	widgetCounter += 1;

	wOpts.widgetId = `widget_${widgetCounter}`;

	return wOpts;
};

const mergeUpdateIntoOptions = (updateParams: Object, options: Object) => {
	const updated = { ...options };

	PAGE_INTEGRATION_PARAMS.forEach((name) => {
		if (typeof updateParams[name] !== "undefined") {
			updated[name] = updateParams[name];
		}
	}); // merge relevant params

	return updated;
};

export default (opts: Object, widgetCallback?: Function, element?: any) => {
	element = getPageElement(element, opts); // we only accept element on init - not on update!

	let widgetOptions = getWidgetOptions(opts, element);

	if (widgetOptions.inlineContainer) {
		if (!getElement(widgetOptions.inlineContainer)) {
			throw new Error("[Cloudinary.UploadWidget]: 'inlineContainer' param must either be a valid HTMLElement or a selector string");
		}
	}

	delete (widgetOptions.element);

	let frameApiPromise;
	let comms;
	let uploadsHandler;
	let isFrameShowing;
	let isFrameMinimized;
	let isDestroyed = false;
	let uploadButton;
	let elementOrgDisplay;

	const triggerEvent = (type, data) => {
		if (widgetOptions.$) {
			widgetOptions.$(element || widgetOptions.form || document).trigger(type, data);
		}
	};

	const buildWidgetFrameUrl = () => {
		let url;
		const isDev = widgetOptions.dev === true;

		if (isDev) {
			url = widgetOptions.widgetHost || `//localhost:${DEV_PORT}/index.html`;
		} else {
			const version = isDev ? "" : (widgetOptions.widgetVersion + "/"),
				isNewUrl = !!~version.indexOf("."),
				urlEnv = widgetOptions.staging === true ? "-staging" : "",
				subDomain = isNewUrl ? APP_SUB_DOMAIN : OLD_SUB_DOMAIN,
				hostName = widgetOptions.widgetBase || `//${subDomain}${urlEnv}.${APP_TOP_DOMAIN}/`;

			//TODO: update when only new pipeline is used
			//support both old and new pipeline
			const pathBase = isNewUrl ? "n" : `v2.0/n/${widgetOptions.cloudName}`;

			url = widgetOptions.widgetHost ||
				`${hostName}${pathBase}/${version}index.html`;
		}

		return url.indexOf("http") !== 0 ?
			(widgetOptions.secure ? "https:" : "http:") + url :
			url;
	};

	const runCallbackIfNotDestroyed = (frameApi, cb) => {
		if (isDestroyed) {
			throw new Error("Widget was destroyed and cannot be used anymore");
		}

		return cb(frameApi);
	};

	const runWithFrameApi = (cb): Promise<any> | any => (frameApiPromise ?
		frameApiPromise
			.then((frameApi) => runCallbackIfNotDestroyed(frameApi, cb))
			.catch((err) => logger.error("Cloudinary.UploadWidget - encountered error ! ", err)) :
		logger.error("Cloudinary.UploadWidget - Widget frame API not ready yet!"));

	const open = (source, showOptions) => runWithFrameApi((frameApi: FrameApi) => {
		frameApi.open(showOptions);

		if (frameApi.isFrameReady()) {
			if (!showOptions?.hidden) {
				comms.sendMessage(MESSAGE_TYPES.SHOW, { source, options: showOptions }, true);
			}

			uploadsHandler.handleFiles(showOptions, getOptions())
				.then(() => {
					if (!showOptions?.hidden) {
						setTimeout(() => { // let the widget do its loading before showing the frame
							frameApi.showWidget();
							comms.sendDisplayChangedCallback(DISPLAY_VALUES.SHOWN);
						}, 150);
					}
				});
		}
	});

	const destroy = (options) =>
		runWithFrameApi((frameApi: FrameApi) => {
			frameApi.remove();
			isDestroyed = true;

			comms.close();

			frameApi = null;
			comms = null;
			uploadsHandler = null;

			if (uploadButton) {
				revertUploadButton(uploadButton);
			}

			if (element?.style) {
				element.style.display = elementOrgDisplay;
			}

			if (options?.removeThumbnails) {
				clearThumbnails(widgetOptions);
			}
		});

	const clientClose = (closeOptions) => runWithFrameApi((frameApi: FrameApi) => {
		frameApi.close();
		comms.sendMessage(MESSAGE_TYPES.HIDE, closeOptions);
	});

	const updateConfig = (updateOptions) : Promise<any> => runWithFrameApi((frameApi: FrameApi) => {
		const params = processParams(updateOptions); // make sure we're using camel casing
		comms.sendMessage(MESSAGE_TYPES.CONFIG, params);
		widgetOptions = mergeUpdateIntoOptions(params, widgetOptions); // merge update into options object

		frameApi.optionsUpdated();
	});

	const minimize = () => {
		comms.sendMessage(MESSAGE_TYPES.MINIMIZE);
	};

	const getOptions = () => widgetOptions;

	const init = () => {
		const widgetHost = buildWidgetFrameUrl();

		frameApiPromise = initIframe(getOptions, widgetHost);

		return frameApiPromise.then((frameApi: FrameApi) => {
			isFrameShowing = frameApi.isWidgetShowing;
			isFrameMinimized = frameApi.isWidgetMinimized;

			// init comms after frame is ready
			comms = initComms(
				getOptions,
				widgetHost,
				{ // actions
					triggerEvent,
					processUploadResult: (result) => processUploadResult(result, element, getOptions(), { triggerEvent }),
					widgetCallback,
					...frameApi,
				},
			);

			const options = getOptions();

			comms.sendMessage(MESSAGE_TYPES.INIT, {
				...options,
				showOnStart: frameApi.isWidgetOpen(),
			});

			uploadsHandler = initUploadsHandler(comms);

			if (element) {
				uploadButton = initUploadButton(element, open, options);
				elementOrgDisplay = element.style?.display;
				element.style.display = "none";
			}
		});
	};

	init();

	//* ********************************************* WIDGET API **********************************************
	return {
		open(source?: string, showOptions?: { files: any[], batchOptions?: Object }) {
			open(source, showOptions);
			return this;
		},

		update(updateOptions: Object) {
			return updateConfig(updateOptions)
				.then(() => this);
		},

		close(closeOptions?: { quiet: boolean }) {
			clientClose(closeOptions);
			return this;
		},

		hide() {
			runWithFrameApi((frameApi) => frameApi.hideWidget());
			return this;
		},

		show() {
			runWithFrameApi((frameApi) => frameApi.showWidget());
			return this;
		},

		minimize() {
			runWithFrameApi(() => minimize() );
			return this;
		},

		isShowing() {
			return !isDestroyed && !!isFrameShowing && isFrameShowing();
		},

		isMinimized() {
			return !isDestroyed && !!isFrameMinimized && isFrameMinimized();
		},

		destroy(options) {
			return destroy(options);
		},

		isDestroyed() {
			return isDestroyed;
		},
	};
};
