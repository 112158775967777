const EVENT_TYPES = {
	UPLOADER_READY: "UPLOADER_READY",
	UPLOADER_FILE_STATUS: "UPLOADER_FILE_STATUS",
	UPLOADER_SHOW: "UPLOADER_SHOW",
	UPLOADER_UPDATE_SETTINGS: "UPLOADER_UPDATE_SETTINGS",
	UPLOADER_CLIENT_HIDE: "UPLOADER_CLIENT_HIDE",
	UPLOADER_CLIENT_TAGS: "UPLOADER_CLIENT_TAGS",
	UPLOADER_CLIENT_UPLOAD_PRESETS: "UPLOADER_CLIENT_UPLOAD_PRESETS",
	UPLOADER_CLIENT_MINIMIZE: "UPLOADER_CLIENT_MINIMIZE",
	UPLOADER_BATCH_STARTED: "UPLOADER_BATCH_STARTED",
	UPLOADER_CROP_STARTED: "UPLOADER_CROP_STARTED",
	UPLOADER_BATCH_CANCELLED: "UPLOADER_BATCH_CANCELLED",
	UPLOADER_CLOUD_INFO: "UPLOADER_CLOUD_INFO",
	UPLOADER_SUCCESS_COUNTER: "UPLOADER_SUCCESS_COUNTER",
	UPLOADER_MD_STARTED: "UPLOADER_MD_STARTED",
};

const EXT_EVENTS = {
	FILE_FINISH: "upload-finish",
	FILE_ADDED: "upload-added",
	WIDGET_VIEW_TYPE_CHANGE: "widget-view-change",
	SOURCE_CHANGED: "source-changed",
	TAGS: "tags",
	PUBLICID: "publicid",
	QUEUES_START: "queues-start",
	QUEUES_END: "queues-end",
	SHOW_COMPLETED: "show-completed",
	ABORT: "abort",
	RETRY: "retry",
	BATCH_CANCELED: "batch-canceled",
	DISPLAY_CHANGED: "display-changed",
};

const MESSAGE_TYPES = {
	INIT: "uw_init", // initialize the widget
	MINIMIZE: "uw_mini", // minimize widget to mini queue
	CONFIG: "uw_config", // update widget configuration
	PREPARE: "uw_prepare", // prepare upload params
	PRE_BATCH: "uw_prebatch", // callback before batch is added
	EVENT: "uw_event", // widget life-time events
	SHOW: "uw_show", // show the widget (after it was hidden)
	HIDE: "uw_hide", // hide the widget
	TAGS: "uw_tags", // retrieve tags callback
	UPLOAD_PRESETS: "uw_upload_presets", // retrieve tags callback
	FILE: "uw_file", // upload file
	CLIENT_BATCH: "uw_clientbatch", // batch from client starting
	METADATA_SCHEMA: "uw_metadata_schema", //callback to get metadata schema
};

export {
	EVENT_TYPES,
	EXT_EVENTS,
	MESSAGE_TYPES,
};
